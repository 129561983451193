<template>
    <div>
        <v-card class="container scrollbar-template">
            <div xs="1" sm="1" md="1" lg="1" class="v-btn-alineacion background-transparent" style="float: right; ">
                <v-btn
                    @click="onClose()"
                    small
                    slot="activator"
                    icon
                    text
                    class="v-btn-cerrar"
                >
                    <v-icon class="icono-cerrar"></v-icon>
                </v-btn>
            </div>
            <v-card-title class="padding_titulo_eventos">
                <div class="tituloEventos">
                    Incidencias del día
                </div>
            </v-card-title>
            <v-card-text class="vCardText_eventos" >
                <template v-for="e in events">
                    <div :key="e.id" style="padding: 0px 0px;">
                        <div class="item-event" :id="e.id" :style="'background: ' + e.originalEvent.color" @click.stop="onSelectItemEvent(e)">
                            <!-- <img :src="e.photo" class="photo-profile" /> -->
                            <!-- <span class="title">{{e.originalEvent.title}} <br /> {{e.originalEvent.subtitle}}</span> -->
                            <v-list color="transparent" class="py-0 my-0" style="background-color: transparent !important;">
                                <v-list-item class="py-0 my-0 padding_izquierdo">
                                    <v-list-item-avatar height="48" width="48" class="py-0 my-0 mr-3">
                                        <v-img class="" height="48" width="48" :src="e.photo"></v-img>
                                    </v-list-item-avatar>
                                    <v-list-item-content class="ml-3 mr-3" style="background-color: transparent !important;">
                                        <v-list-item-title 
                                            class="evento_empleado text-wrap" 
                                            style="background-color: transparent !important;"
                                        >
                                            {{e.originalEvent.title}}
                                        </v-list-item-title>
                                        <v-list-item-title 
                                            class="evento_subtitulo text-wrap" 
                                            style="background-color: transparent !important;"
                                        >
                                            {{e.originalEvent.subtitle}}
                                        </v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>
                        </div>

                        <transition name="fade">
                            <template v-if="selected == e.id">
                                <div class="history-tl-container">
                                    <ul :style="timeLineStyle" class="tl">
                                        <li class="tl-item">
                                            <div class="item-title">Inicio: {{dateFormat(e.originalEvent.startDate)}}</div>
                                        </li>
                                        <li class="tl-item">
                                            <div class="item-title">Fin: {{dateFormat(e.originalEvent.endDate)}}</div>
                                        </li>
                                    </ul>
                                </div>
                            </template>
                        </transition>
                    </div>
                </template>
                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                </v-col>
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
import PerfectScrollbar from 'perfect-scrollbar';
export default {
    props: ['events'],
    data() {
        return {
            selected: null,
            selectedColor: '#96999A'
        }
    },
    computed: {
        timeLineStyle() {
            return {
                '--time-color': this.selectedColor
            }
        }
    },
    methods: {
        onClose() {
            this.$modal.hide('calendarEventsModal');
        },
        onSelectItemEvent(event) {
            this.selectedColor  = event.originalEvent.color;
            this.selected       = event.id;
        },
        async scrollPerfect(){
            var myCustomScrollbar = document.querySelector('.scrollbar-template');
            var ps = new PerfectScrollbar(myCustomScrollbar);

            var scrollbarY = myCustomScrollbar.querySelector('.ps__rail-y');

            myCustomScrollbar.onscroll = function () {
                scrollbarY.style.cssText = `
                                                top: ${this.scrollTop}px!important; 
                                                height: 200px; 
                                                background-color:transparent; 
                                                right: ${-this.scrollLeft}px;
                                            `;
            }
        },
        dateFormat(fecha) {
            let fecha2 = fecha.split("-");
            return fecha2[2] + "/" + fecha2[1]+ "/" + fecha2[0];
        },
    },
    mounted() {
        document.getElementsByClassName("vm--modal")[0].style.borderRadius = "25px";
        this.scrollPerfect();
    }
}
</script>

<style scoped>
    .container {
        background-color: white;
        overflow-y: scroll;
        height: 569px;
        /* padding-right: 0px !important;
        padding-left: 0px !important; */
        padding: 0px;
    }

    .btn-close {
        width: 100%;
        position: absolute;

        margin-top: 15px;
        padding-right: 28px;
        padding-bottom: 5px;
    }
    .close-color {
        color: #96999A !important;
    }
    .close-size {
        font-size: 16px !important;
        cursor: pointer;
    }

    .item-event {
        /* width:100%; */
        min-height: 96px;

        /* border-radius: 80px; */
        /* padding: 10px; */

        display: flex;
        align-items:center;
        cursor: pointer;
    }

    .item-event:hover {
        background: #C7111180;
    }

    .photo-profile {
        height: 74px;
        width: 74px;

        border-radius: 50%;
        margin-left: 16px;
    }

    .title {
        font-family: 'Montserrat' !important;
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 115.9%;

        color: #FFFFFF;
        padding-left: 59px;
    }

    .history-tl-container{
        width: 63%;
        margin: auto;
        display: block;
        position: relative;
    }
    .history-tl-container ul.tl{
        margin-top: 20px;
        padding: 0;
        display: inline-block;
    }
    .history-tl-container ul.tl li{
        list-style: none;
        margin: auto;
        /* margin-left:200px; */
        min-height: 20px;
        /*background: rgba(255,255,0,0.1);*/
        border-left-width: 1px;
        border-left-style: dashed;
        border-left-color: var(--time-color);
        padding: 0 0 20px 30px;
        position: relative;
    }
    .history-tl-container ul.tl li:last-child {
        border-left:0;
    }
    .history-tl-container ul.tl li::before {
        position: absolute;
        left: -6.12px;
        top: 0px;
        content: " ";
        /* border: 8px solid rgba(255, 255, 255, 0.74); */
        border-radius: 500%;
        background: var(--time-color);
        height: 12px;
        width: 12px;
        transition: all 500ms ease-in-out;
    }
    ul.tl li .item-title{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 15px;

        color: #828282;
    }

    .fade-enter-active, .fade-leave-active {
        transition: opacity .5s;
    }
    .fade-enter, .fade-leave-to {
        opacity: 0;
    }
    .v-btn-cerrar .v-btn:hover::before {
        background-color: transparent !important;
        pointer-events: none;
    }
    .v-btn-cerrar .v-btn:hover::after {
        background-color: transparent !important;
        pointer-events: none;
    }
    .v-btn-cerrar .v-btn:hover {
        background-color: transparent !important;
        pointer-events: none;
    }
    .v-btn-alineacion{
        /* text-align: end !important; */
        margin-right: 15px !important;
        margin-top: 10px !important;
        background: transparent !important;
    }
    .background-transparent{
        background: transparent !important;
    }
    .vCardText_eventos{
        padding-right: 0px !important;
        padding-left: 0px !important;
    }
    .tituloEventos{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 20px;
        color: #1E2245;
    }
    .evento_empleado{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 16px;
        color: #FFFFFF;
        padding-left: 10px;
    }
    .evento_subtitulo{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        line-height: 14px;
        font-size: 12px;
        color: #FFFFFF;
        padding-left: 10px;
    }
    .padding_titulo_eventos{
        padding-left: 50px !important;
        padding-right: 50px !important;
        padding-bottom: 35px !important;
        padding-top: 50px !important;
    }
</style>