<template>
	<div class="cv-header">
		<div class="periodLabel">
			<i v-if="!headerProps.showBirthdayIcon" class="material-icons calendar-icon">event</i>
			<i v-else class="material-icons-outlined calendar-icon">cake</i>
			<slot name="label">{{ headerProps.periodLabel }}</slot>
		</div>
		<div class="cv-header-nav">
			<button
				:disabled="!headerProps.previousPeriod"
				class="previousPeriod"
				@click.prevent="onInput(headerProps.previousPeriod)"
			>
				<img src="/static/icon/calendar-previous.png" class="nav-image" />
			</button>
			<button
				:disabled="!headerProps.nextPeriod"
				class="nextPeriod"
				@click.prevent="onInput(headerProps.nextPeriod)"
			>
				<img src="/static/icon/calendar-next.png" class="nav-image" />
			</button>
		</div>
	</div>
</template>
<script>
export default {
	name: "CkCalendarHeader",
	props: {
		headerProps: {
			type: Object,
			required: true,
		},
	},
	methods: {
		onInput(d) {
			this.$emit("input", d)
		},
	},
}
</script>
<style>
.cv-header {
	display: flex;
	flex: 0 1 auto;
	flex-flow: row nowrap;
	align-items: center;
	min-height: 2.5em;
	border-width: 1px 1px 0 1px;

	background-color: #f6f5f6;
	/*opacity: 0.1;*/
	border-top-right-radius: 20PX;
	border-top-left-radius: 20px;
}
.cv-header .periodLabel {
	display: flex;
	align-items: center;
	flex: 1 1 auto;
	flex-flow: row nowrap;

	font-family: 'Montserrat';
	font-style: normal;
	font-weight: bold;
	font-size: 32px;
	text-transform: capitalize;
	line-height: 39px;

	color: #1E2245;
}
.cv-header .periodLabel .calendar-icon {
	color: #2DB9D1;
	margin-right: 21px;
}

/* .cv-header,
.cv-header button {
	border-style: solid;
	border-color: #ddd;
} */
.cv-header-nav,
.cv-header .periodLabel {
	margin: 0.1em 0.6em;
}
.cv-header-nav button,
.cv-header .periodLabel {
	padding: 0.4em 0.6em;
}
.cv-header button {
	background-color: #FFF;
	border: #eff1fb solid 1px;
	box-sizing: border-box;
	line-height: 1em;
	font-size: 1em;
	border-width: 1px;
	color: #bdbdbd;
}
.cv-header-nav button:focus {
	outline: 0;
}
.cv-header-nav button:hover {
	background-color: #F3EFF3;
}
button.previousPeriod {
	width: 44px;
	height: 44px;
	border-top-left-radius: 15px;
	border-bottom-left-radius: 15px;
}
button.nextPeriod {
	width: 44px;
	height: 44px;
	border-top-right-radius: 15px;
	border-bottom-right-radius: 15px;
}
.nav-image {
	margin-top: 5px;
	height: 14px;
	width: 14px;
}
</style>