var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
    'cv-wrapper',
    'locale-' + _vm.languageCode(_vm.displayLocale),
    'locale-' + _vm.displayLocale,
    'y' + _vm.periodStart.getFullYear(),
    'm' + _vm.paddedMonth(_vm.periodStart),
    'period-' + _vm.displayPeriodUom,
    'periodCount-' + _vm.displayPeriodCount,
    {
      past: _vm.isPastMonth(_vm.periodStart),
      future: _vm.isFutureMonth(_vm.periodStart),
      noIntl: !_vm.supportsIntl,
    },
  ]},[_vm._t("header",null,{"headerProps":_vm.headerProps}),_c('div',{staticClass:"cv-header-days"},[_vm._l((_vm.weekdayNames),function(label,index){return [_vm._t("dayHeader",function(){return [_c('div',{key:_vm.getColumnDOWClass(index),staticClass:"cv-header-day",class:_vm.getColumnDOWClass(index)},[_vm._v(" "+_vm._s(label)+" ")])]},{"index":_vm.getColumnDOWClass(index),"label":label})]})],2),_c('div',{staticClass:"cv-weeks"},_vm._l((_vm.weeksOfPeriod),function(weekStart,weekIndex){return _c('div',{key:`${weekIndex}-week`,class:[
        'cv-week',
        'week' + (weekIndex + 1),
        'ws' + _vm.isoYearMonthDay(weekStart),
      ]},[_vm._l((_vm.daysOfWeek(weekStart)),function(day,dayIndex){return _c('div',{key:_vm.getColumnDOWClass(dayIndex),class:[
          'cv-day',
          _vm.getColumnDOWClass(dayIndex),
          'd' + _vm.isoYearMonthDay(day),
          'd' + _vm.isoMonthDay(day),
          'd' + _vm.paddedDay(day),
          'instance' + _vm.instanceOfMonth(day),
          {
            today: _vm.isSameDate(day, _vm.today()),
            outsideOfMonth: !_vm.isSameMonth(day, _vm.defaultedShowDate),
            past: _vm.isInPast(day),
            future: _vm.isInFuture(day),
            last: _vm.isLastDayOfMonth(day),
            lastInstance: _vm.isLastInstanceOfMonth(day),
          },
          ...((_vm.dateClasses && _vm.dateClasses[_vm.isoYearMonthDay(day)]) || _vm.arr),
          _vm.getHolidayClass(weekStart, _vm.isoYearMonthDay(day)),
        ],on:{"click":function($event){return _vm.onClickDay(day)},"drop":function($event){$event.preventDefault();return _vm.onDrop(day, $event)},"dragover":function($event){$event.preventDefault();return _vm.onDragOver(day)},"dragenter":function($event){$event.preventDefault();return _vm.onDragEnter(day, $event)},"dragleave":function($event){$event.preventDefault();return _vm.onDragLeave(day, $event)}}},[_c('div',{staticClass:"cv-day-number"},[_c('div',[_vm._v(_vm._s(day.getDate()))])]),_vm._t("dayContent",null,{"day":day})],2)}),_vm._l((_vm.getWeekItems(weekStart)),function(e){return [(e.eventRow >= 3)?[_c('div',{key:e.id + '-m',staticClass:"text-end",class:['cv-event-more', _vm.getOffsetClass(e.classes), 'span1']},[_c('v-btn',{staticClass:"btn_ver_mas",attrs:{"rounded":"","outlined":"","x-small":"","color":"#BDBDBD"},on:{"click":function($event){$event.stopPropagation();return _vm.showCardInfo(e, weekStart)}}},[_vm._v(" Ver más ")])],1)]:_vm._e(),(e.eventRow <= 2)?_vm._t("event",function(){return [_c('div',{key:e.id,staticClass:"cv-event",class:e.classes,style:(`top:${_vm.getItemTop(e)};${e.originalEvent.style};${_vm.setItemHeight(
                e
              )};${_vm.setItemColor(e)}`),attrs:{"draggable":_vm.enableDragDrop},on:{"click":function($event){$event.stopPropagation();return _vm.onClickItem(e, $event)}}},[_c('span',{staticClass:"event-title"},[_vm._v("  "+_vm._s(e.title))]),_c('br'),_vm._v("  "+_vm._s(e.subtitle)+" ")])]},{"event":e,"weekStartDate":weekStart,"top":_vm.getItemTop(e)}):_vm._e()]}),_vm._l((_vm.getWeekHolidayItems(weekStart)),function(h){return [(h.eventRow == 0)?_vm._t("holiday",function(){return [_c('div',{key:'h-' + h.id,staticClass:"cv-holiday",class:h.classes,attrs:{"draggable":false,"title":h.title},domProps:{"innerHTML":_vm._s('Día festivo')},on:{"click":function($event){return _vm.onClickHolidayItem(h, $event)}}})]},{"holiday":h,"weekStartDate":weekStart,"top":_vm.getItemTop(h)}):_vm._e()]}),_vm._l((_vm.getWeekBirthdayItems(weekStart)),function(b){return [(b.eventRow == 0)?_vm._t("birthday",function(){return [_c('div',{key:'b-' + b.id,staticClass:"cv-birthday",class:[
              b.classes,
              {
                female: !_vm.getWeekBirthdayGender(
                  b.originalEvent.startDate,
                  weekStart
                ),
              },
            ],attrs:{"draggable":false}},[_c('i',{staticClass:"material-icons-outlined"},[_vm._v("cake")])]),_c('div',{key:'click-' + b.id,staticClass:"cv-birthday2",class:[
                b.classes,
                {
                  female: !_vm.getWeekBirthdayGender(
                    b.originalEvent.startDate,
                    weekStart
                  ),
                },
              ],on:{"click":function($event){$event.stopPropagation();return _vm.showBirthdayCardInfo(b, weekStart)}}})]},{"birthday":b,"weekStartDate":weekStart,"top":_vm.getItemTop(b)}):_vm._e(),(b.eventRow == 0)?[_c('div',{key:b.id + '-av',class:['cv-avatar-more', _vm.getOffsetClass(b.classes), 'span1']},[(
                _vm.getWeekBirthdayAvatars(b.originalEvent.startDate, weekStart)
                  .length == 1
              )?_c('div',{staticClass:"avatars",on:{"click":function($event){$event.stopPropagation();return _vm.showBirthdayCardInfo(b, weekStart)}}},[_c('span',{staticClass:"avatar",attrs:{"title":`${b.title} - ${b.subtitle}`}},[_c('img',{attrs:{"src":b.photo}})])]):_c('div',{staticClass:"avatars"},[_vm._l((_vm.getWeekBirthdayAvatars(
                  b.originalEvent.startDate,
                  weekStart
                )),function(avatar,index){return [(index <= 2)?_c('span',{key:b.id + '-av-photos-' + avatar.id,staticClass:"avatar",attrs:{"title":`${avatar.title} - ${avatar.subtitle}`},on:{"click":function($event){$event.stopPropagation();return _vm.showBirthdayCardInfo(b, weekStart)}}},[_c('img',{attrs:{"src":avatar.photo}})]):_vm._e(),(index == 3)?_c('span',{key:b.id + '-av-photos-more-' + avatar.id,staticClass:"avatar info",staticStyle:{"border":"1px solid #96999a !important","background-color":"#fff !important","color":"#96999a !important"},on:{"click":function($event){$event.stopPropagation();return _vm.showBirthdayCardInfo(b, weekStart)}}},[_vm._v(" "+_vm._s("+" + (_vm.getWeekBirthdayAvatars( b.originalEvent.startDate, weekStart ).length - 3))+" ")]):_vm._e()]})],2)])]:_vm._e()]})],2)}),0)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }