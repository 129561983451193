<template>
  <div
    :class="[
      'cv-wrapper',
      'locale-' + languageCode(displayLocale),
      'locale-' + displayLocale,
      'y' + periodStart.getFullYear(),
      'm' + paddedMonth(periodStart),
      'period-' + displayPeriodUom,
      'periodCount-' + displayPeriodCount,
      {
        past: isPastMonth(periodStart),
        future: isFutureMonth(periodStart),
        noIntl: !supportsIntl,
      },
    ]"
  >
    <slot :header-props="headerProps" name="header" />
    <div class="cv-header-days">
      <template v-for="(label, index) in weekdayNames">
        <slot :index="getColumnDOWClass(index)" :label="label" name="dayHeader">
          <div
            :key="getColumnDOWClass(index)"
            :class="getColumnDOWClass(index)"
            class="cv-header-day"
          >
            {{ label }}
          </div>
        </slot>
      </template>
    </div>
    <div class="cv-weeks">
      <div
        v-for="(weekStart, weekIndex) in weeksOfPeriod"
        :key="`${weekIndex}-week`"
        :class="[
          'cv-week',
          'week' + (weekIndex + 1),
          'ws' + isoYearMonthDay(weekStart),
        ]"
      >
        <div
          v-for="(day, dayIndex) in daysOfWeek(weekStart)"
          :key="getColumnDOWClass(dayIndex)"
          :class="[
            'cv-day',
            getColumnDOWClass(dayIndex),
            'd' + isoYearMonthDay(day),
            'd' + isoMonthDay(day),
            'd' + paddedDay(day),
            'instance' + instanceOfMonth(day),
            {
              today: isSameDate(day, today()),
              outsideOfMonth: !isSameMonth(day, defaultedShowDate),
              past: isInPast(day),
              future: isInFuture(day),
              last: isLastDayOfMonth(day),
              lastInstance: isLastInstanceOfMonth(day),
            },
            ...((dateClasses && dateClasses[isoYearMonthDay(day)]) || arr),
            getHolidayClass(weekStart, isoYearMonthDay(day)),
          ]"
          @click="onClickDay(day)"
          @drop.prevent="onDrop(day, $event)"
          @dragover.prevent="onDragOver(day)"
          @dragenter.prevent="onDragEnter(day, $event)"
          @dragleave.prevent="onDragLeave(day, $event)"
        >
          <div class="cv-day-number">
            <div>{{ day.getDate() }}</div>
          </div>
          <slot :day="day" name="dayContent" />
        </div>

        <template v-for="e in getWeekItems(weekStart)">
          <template v-if="e.eventRow >= 3">
            <!-- <div
              :key="e.id + '-m'"
              :class="['cv-event-more', getOffsetClass(e.classes), 'span1']"
            >
              <div @click.stop="showCardInfo(e, weekStart)">Ver más</div>
            </div> -->
            <div :key="e.id + '-m'" class="text-end" :class="['cv-event-more', getOffsetClass(e.classes), 'span1']">
              <v-btn
                rounded
                outlined
                x-small
                color="#BDBDBD"
                class="btn_ver_mas"
                @click.stop="showCardInfo(e, weekStart)"
              >
                Ver más
              </v-btn>
            </div>
          </template>          
          <slot
            v-if="e.eventRow <= 2"
            :event="e"
            :weekStartDate="weekStart"
            :top="getItemTop(e)"
            name="event"
          >
            <div
              :key="e.id"
              :draggable="enableDragDrop"
              :class="e.classes"
              :style="
                `top:${getItemTop(e)};${e.originalEvent.style};${setItemHeight(
                  e
                )};${setItemColor(e)}`
              "
              class="cv-event"
              @click.stop="onClickItem(e, $event)"
            >
              <span class="event-title"> &nbsp;{{ e.title }}</span>
              <br />
              &nbsp;{{ e.subtitle }}
            </div>
          </slot>
        </template>

        <template v-for="h in getWeekHolidayItems(weekStart)">
          <slot
            v-if="h.eventRow == 0"
            :holiday="h"
            :weekStartDate="weekStart"
            :top="getItemTop(h)"
            name="holiday"
          >
            <div
              :key="'h-' + h.id"
              :draggable="false"
              :class="h.classes"
              :title="h.title"
              class="cv-holiday"
              @click="onClickHolidayItem(h, $event)"
              v-html="'Día festivo'"
            />
          </slot>
        </template>

        <template v-for="b in getWeekBirthdayItems(weekStart)">
          <slot
            v-if="b.eventRow == 0"
            :birthday="b"
            :weekStartDate="weekStart"
            :top="getItemTop(b)"
            name="birthday"
          >
            <div
              :key="'b-' + b.id"
              :draggable="false"
              :class="[
                b.classes,
                {
                  female: !getWeekBirthdayGender(
                    b.originalEvent.startDate,
                    weekStart
                  ),
                },
              ]"
              class="cv-birthday"
            >
              <i class="material-icons-outlined">cake</i>
            </div>
            <div
              @click.stop="showBirthdayCardInfo(b, weekStart)"
              :key="'click-' + b.id"
              :class="[
                  b.classes,
                  {
                    female: !getWeekBirthdayGender(
                      b.originalEvent.startDate,
                      weekStart
                    ),
                  },
                ]"
                class="cv-birthday2"
            ></div>
          </slot>

          <template v-if="b.eventRow == 0">
            <div
              :key="b.id + '-av'"
              :class="['cv-avatar-more', getOffsetClass(b.classes), 'span1']"
            >
              <div
                class="avatars"
                v-if="
                  getWeekBirthdayAvatars(b.originalEvent.startDate, weekStart)
                    .length == 1
                "
                @click.stop="showBirthdayCardInfo(b, weekStart)"
              >
                <span class="avatar" :title="`${b.title} - ${b.subtitle}`">
                  <img :src="b.photo" />
                </span>
              </div>

              <div class="avatars" v-else>
                <template
                  v-for="(avatar, index) in getWeekBirthdayAvatars(
                    b.originalEvent.startDate,
                    weekStart
                  )"
                >
                  <span
                    :key="b.id + '-av-photos-' + avatar.id"
                    class="avatar"
                    v-if="index <= 2"
                    :title="`${avatar.title} - ${avatar.subtitle}`"
                    @click.stop="showBirthdayCardInfo(b, weekStart)"
                  >
                    <img :src="avatar.photo" />
                  </span>

                  <span
                    v-if="index == 3"
                    :key="b.id + '-av-photos-more-' + avatar.id"
                    class="avatar info"
                    style="border: 1px solid #96999a !important;
                          background-color: #fff !important;  color: #96999a !important;"
                    @click.stop="showBirthdayCardInfo(b, weekStart)"
                  >
                    {{
                      "+" +
                        (getWeekBirthdayAvatars(
                          b.originalEvent.startDate,
                          weekStart
                        ).length -
                          3)
                    }}
                  </span>
                </template>
              </div>
            </div>
          </template>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import CkCalendarMixin from "./CkCalendarMixin";
import CkModalEvents from "./CkModalEvents";
import CkModalBirthdays from "./CkModalBirthdays";
import CkModalDay from "./CkModalDay";

export default {
  name: "CkCalendar",
  mixins: [CkCalendarMixin],
  props: {
    showDate: { type: Date, default: undefined },
    displayPeriodUom: { type: String, default: "month" },
    displayPeriodCount: { type: Number, default: 1 },
    locale: { type: String, default: undefined },
    monthNameFormat: { type: String, default: "long" },
    weekdayNameFormat: { type: String, default: "short" },
    showEventTimes: { type: Boolean, default: false },
    timeFormatOptions: { type: Object, default: () => {} },
    disablePast: { type: Boolean, default: false },
    disableFuture: { type: Boolean, default: false },
    enableDragDrop: { type: Boolean, default: false },
    startingDayOfWeek: { type: Number, default: 0 },
    events: { type: Array, default: () => [] },
    holidays: { type: Array, default: () => [] },
    birthdays: { type: Array, default: () => [] },
    dateClasses: { type: Object, default: () => {} },
    eventTop: { type: String, default: "2.6em" },
    eventContentHeight: { type: String, default: "2.6em" },
    eventBorderHeight: { type: String, default: "2px" },
    periodChangedCallback: { type: Function, default: undefined },
    currentPeriodLabel: { type: String, default: "" },
    currentPeriodLabelIcons: { type: String, default: "⇤-⇥" },
    doEmitItemMouseEvents: { type: Boolean, default: false },
    showBirthdayIcon: { type: Boolean, default: false },
  },
  data: () => ({
    currentDragItem: null,
    currentHoveredItemId: undefined,
    arr:[]
  }),
  computed: {
    /*
  		Props cannot default to computed/method returns, so create defaulted version of this
  		property and use it rather than the bare prop (Vue Issue #6013).
  		*/
    displayLocale() {
      return this.locale || this.getDefaultBrowserLocale();
    },
    /*
  		ShowDate, but defaulted to today. Needed both for periodStart below and for the
  		"outside of month" class. Any time component passed as part of showDate is discarded.
  		*/
    defaultedShowDate() {
      if (this.showDate) return this.dateOnly(this.showDate);
      return this.today();
    },
    /*
  		Given the showDate, defaulted to today, computes the beginning and end of the period
  		that the date falls within.
  		*/
    periodStart() {
      return this.beginningOfPeriod(
        this.defaultedShowDate,
        this.displayPeriodUom,
        this.startingDayOfWeek
      );
    },
    periodEnd() {
      return this.addDays(
        this.incrementPeriod(
          this.periodStart,
          this.displayPeriodUom,
          this.displayPeriodCount
        ),
        -1
      );
    },
    /*
  		For month and year views, the first and last dates displayed in the grid may not
  		be the same as the intended period, since the period may not start and stop evenly
  		on the starting day of the week.
  		*/
    displayFirstDate() {
      return this.beginningOfWeek(this.periodStart, this.startingDayOfWeek);
    },
    displayLastDate() {
      return this.endOfWeek(this.periodEnd, this.startingDayOfWeek);
    },
    /*
  		Create an array of dates, where each date represents the beginning of a week that
  		should be rendered in the view for the current period.
  		*/
    weeksOfPeriod() {
      // Returns an array of object representing the date of the beginning of each week
      // included in the view.
      const numWeeks = Math.floor(
        (this.dayDiff(this.displayFirstDate, this.displayLastDate) + 1) / 7
      );
      return Array(numWeeks)
        .fill()
        .map((_, i) => this.addDays(this.displayFirstDate, i * 7));
    },
    // Cache the names based on current locale and format settings
    monthNames() {
      return this.getFormattedMonthNames(
        this.displayLocale,
        this.monthNameFormat
      );
    },
    weekdayNames() {
      return this.getFormattedWeekdayNames(
        this.displayLocale,
        this.weekdayNameFormat,
        this.startingDayOfWeek
      );
    },
    // Ensure all item properties have suitable default
    fixedItems() {
      const self = this;
      return this.events.map((e) =>
        self.normalizeEvent(
          e,
          self.currentHoveredItemId && e.id === self.currentHoveredItemId
        )
      );
    },
    // Ensure all holiday properties have suitable default
    fixedHolidayItems() {
      const self = this;
      return this.holidays.map((h) =>
        self.normalizeEvent(
          h,
          self.currentHoveredItemId && h.id === self.currentHoveredItemId
        )
      );
    },
    // Ensure all birthday properties have suitable default
    fixedBirthdayItems() {
      const self = this;
      return this.birthdays.map((b) =>
        self.normalizeEvent(
          b,
          self.currentHoveredItemId && b.id === self.currentHoveredItemId
        )
      );
    },
    // Creates the HTML to render the date range for the calendar header.
    periodLabel() {
      return this.formattedPeriod(
        this.periodStart,
        this.periodEnd,
        this.displayPeriodUom,
        this.monthNames
      );
    },
    // Period that today's date sits within
    currentPeriodStart() {
      return this.beginningOfPeriod(
        this.today(),
        this.displayPeriodUom,
        this.startingDayOfWeek
      );
    },
    currentPeriodEnd() {
      return this.addDays(
        this.incrementPeriod(
          this.currentPeriodStart,
          this.displayPeriodUom,
          this.displayPeriodCount
        ),
        -1
      );
    },
    currentPeriodLabelFinal() {
      const c = this.currentPeriodStart;
      const s = this.periodStart;
      if (!this.currentPeriodLabel)
        return this.formattedPeriod(
          c,
          this.currentPeriodEnd,
          this.displayPeriodUom,
          this.monthNames
        );
      if (this.currentPeriodLabel === "icons")
        return this.currentPeriodLabelIcons[Math.sign(c - s) + 1];
      return this.currentPeriodLabel;
    },
    headerProps() {
      return {
        // Dates for UI navigation
        previousYear: this.getIncrementedPeriod(-12),
        previousPeriod: this.getIncrementedPeriod(-1),
        nextPeriod: this.getIncrementedPeriod(1),
        previousFullPeriod: this.getIncrementedPeriod(-this.displayPeriodCount),
        nextFullPeriod: this.getIncrementedPeriod(this.displayPeriodCount),
        nextYear: this.getIncrementedPeriod(12),
        currentPeriod: this.currentPeriodStart,
        currentPeriodLabel: this.currentPeriodLabelFinal,
        // Dates for header display
        periodStart: this.periodStart,
        periodEnd: this.periodEnd,
        // Extra information that could be useful to a custom header
        displayLocale: this.displayLocale,
        displayFirstDate: this.displayFirstDate,
        displayLastDate: this.displayLastDate,
        monthNames: this.monthNames,
        fixedEvents: this.fixedItems,
        periodLabel: this.periodLabel,
        showBirthdayIcon: this.showBirthdayIcon,
      };
    },
    periodRange() {
      return {
        periodStart: this.periodStart,
        periodEnd: this.periodEnd,
        displayFirstDate: this.displayFirstDate,
        displayLastDate: this.displayLastDate,
      };
    },
  },
  watch: {
    periodRange: {
      immediate: true,
      handler(newVal) {
        if (this.periodChangedCallback) {
          this.$emit("period-changed");
          this.periodChangedCallback(newVal, "watch");
        }
      },
    },
  },
  methods: {
    // ******************************
    // UI Events
    // ******************************
    onClickDay(day, windowEvent) {
      if (this.disablePast && this.isInPast(day)) return;
      if (this.disableFuture && this.isInFuture(day)) return;
      this.$emit("click-date", day, windowEvent);
    },
    onClickItem(calendarItem, windowEvent) {
      this.$emit("click-event", calendarItem, windowEvent);
    },
    onClickHolidayItem(calendarItem, windowEvent) {
      const results = this.holidays.filter(
        (item) => item.startDate == calendarItem.originalEvent.startDate
      );
      this.$modal.show(
        CkModalDay,
        {
          holidays: results,
          selectedDate: calendarItem,
        },
        {
          name: "calendarDayModal",
          height: 569,
          width: 400,
        }
      );
    },
    /*
  		The day name header needs to know the dow for class assignment, and this value should
  		not change based on startingDayOfWeek (i.e., Sunday is always 0). This function
  		computes the dow for a given day index.
  		*/
    getColumnDOWClass(dayIndex) {
      return "dow" + ((dayIndex + this.startingDayOfWeek) % 7);
    },
    getHolidayClass(weekStart, isoDate) {
      let holidayClass = "";
      let items = this.fixedHolidayItems.filter(
        (d) => d.originalEvent.startDate == isoDate
      );

      if (items.length > 0) holidayClass = "cv-day-holiday";

      return holidayClass;
    },
    getOffsetClass(arrClasses) {
      let index = 0;
      arrClasses.forEach((item, i) => {
        if (item.match(/offset/) != null) index = i;
      });

      return arrClasses[index];
    },
    // ******************************
    // Date Periods
    // ******************************
    /*
  		Returns a date for the current display date moved forward or backward by a given
  		number of the current display units. Returns null if said move would result in a
  		disallowed display period.
  		*/
    getIncrementedPeriod(count) {
      const newStartDate = this.incrementPeriod(
        this.periodStart,
        this.displayPeriodUom,
        count
      );
      const newEndDate = this.incrementPeriod(
        newStartDate,
        this.displayPeriodUom,
        this.displayPeriodCount
      );
      if (this.disablePast && newEndDate <= this.today()) return null;
      if (this.disableFuture && newStartDate > this.today()) return null;
      return newStartDate;
    },
    // ******************************
    // Hover items (#95, #136)
    // ******************************
    onMouseEnterItem(calendarItem, windowEvent) {
      this.currentHoveredItemId = calendarItem.id;
      if (this.doEmitItemMouseEvents) {
        this.$emit("item-mouseenter", calendarItem, windowEvent);
      }
    },
    onMouseLeaveItem(calendarItem, windowEvent) {
      this.currentHoveredItemId = undefined;
      if (this.doEmitItemMouseEvents) {
        this.$emit("item-mouseleave", calendarItem, windowEvent);
      }
    },
    // ******************************
    // Drag and drop items
    // ******************************
    onDragStart(calendarItem, windowEvent) {
      if (!this.enableDragDrop) return false;
      // Not using dataTransfer.setData to store the item ID because it (a) doesn't allow access to the data being
      // dragged during dragover, dragenter, and dragleave events, and because storing an ID requires an unnecessary
      // lookup. This does limit the drop zones to areas within this instance of this component.
      this.currentDragItem = calendarItem;
      // Firefox and possibly other browsers require dataTransfer to be set, even if the value is not used. IE11
      // requires that the first argument be exactly "text" (not "text/plain", etc.).
      windowEvent.dataTransfer.setData("text", "foo");
      this.$emit("drag-start", calendarItem);
      return true;
    },
    handleDragEvent(bubbleEventName, bubbleParam) {
      if (!this.enableDragDrop) return false;
      if (!this.currentDragItem) {
        // shouldn't happen
        // If current drag item is not set, check if user has set its own slot for items
        if (!this.$scopedSlots["event"]) return false;
      }
      this.$emit(bubbleEventName, this.currentDragItem, bubbleParam);
      return true;
    },
    onDragOver(day) {
      this.handleDragEvent("drag-over-date", day);
    },
    onDragEnter(day, windowEvent) {
      if (!this.handleDragEvent("drag-enter-date", day)) return;
      windowEvent.target.classList.add("draghover");
    },
    onDragLeave(day, windowEvent) {
      if (!this.handleDragEvent("drag-leave-date", day)) return;
      windowEvent.target.classList.remove("draghover");
    },
    onDrop(day, windowEvent) {
      if (!this.handleDragEvent("drop-on-date", day)) return;
      windowEvent.target.classList.remove("draghover");
    },
    // ******************************
    // Calendar Items
    // ******************************
    findAndSortItemsInWeek(weekStart) {
      // Return a list of items that INCLUDE any day of a week starting on a
      // particular day. Sorted so the items that start earlier are always
      // shown first.
      const items = this.fixedItems
        .filter(
          (item) =>
            item.startDate < this.addDays(weekStart, 7) &&
            item.endDate >= weekStart,
          this
        )
        .sort((a, b) => {
          if (a.startDate < b.startDate) return -1;
          if (b.startDate < a.startDate) return 1;
          if (a.endDate > b.endDate) return -1;
          if (b.endDate > a.endDate) return 1;
          return a.id < b.id ? -1 : 1;
        });
      return items;
    },
    findAndSortHolidayItemsInWeek(weekStart) {
      // Return a list of items that INCLUDE any day of a week starting on a
      // particular day. Sorted so the items that start earlier are always
      // shown first.
      const items = this.fixedHolidayItems
        .filter(
          (item) =>
            item.startDate < this.addDays(weekStart, 7) &&
            item.endDate >= weekStart,
          this
        )
        .sort((a, b) => {
          if (a.startDate < b.startDate) return -1;
          if (b.startDate < a.startDate) return 1;
          if (a.endDate > b.endDate) return -1;
          if (b.endDate > a.endDate) return 1;
          return a.id < b.id ? -1 : 1;
        });
      return items;
    },
    findAndSortBirthdayItemsInWeek(weekStart) {
      // Return a list of items that INCLUDE any day of a week starting on a
      // particular day. Sorted so the items that start earlier are always
      // shown first.
      const items = this.fixedBirthdayItems
        .filter(
          (item) =>
            item.startDate < this.addDays(weekStart, 7) &&
            item.endDate >= weekStart,
          this
        )
        .sort((a, b) => {
          if (a.startDate < b.startDate) return -1;
          if (b.startDate < a.startDate) return 1;
          if (a.endDate > b.endDate) return -1;
          if (b.endDate > a.endDate) return 1;
          return a.id < b.id ? -1 : 1;
        });
      return items;
    },
    getWeekItems(weekStart) {
      // Return a list of items that CONTAIN the week starting on a day.
      // Sorted so the items that start earlier are always shown first.
      const items = this.findAndSortItemsInWeek(weekStart);
      const results = [];
      const itemRows = [[], [], [], [], [], [], []];
      for (let i = 0; i < items.length; i++) {
        const ep = Object.assign({}, items[i], {
          classes: [...items[i].classes],
          eventRow: 0,
          hasMultiple: true,
        });

        const hasMultiple = this.getMultipleEvents(ep, items);
        const continued = ep.startDate < weekStart;
        const startOffset = continued
          ? 0
          : this.dayDiff(weekStart, ep.startDate);
        const span = Math.min(
          7 - startOffset,
          this.dayDiff(this.addDays(weekStart, startOffset), ep.endDate) + 1
        );
        if (!hasMultiple) ep.hasMultiple = false;
        if (continued) ep.classes.push("continued");
        if (this.dayDiff(weekStart, ep.endDate) > 6)
          ep.classes.push("toBeContinued");
        if (this.isInPast(ep.endDate)) ep.classes.push("past");
        if (ep.originalEvent.url) ep.classes.push("hasUrl");
        for (let d = 0; d < 7; d++) {
          if (d === startOffset) {
            let s = 0;
            while (itemRows[d][s]) s++;
            ep.eventRow = s;
            itemRows[d][s] = true;
          } else if (d < startOffset + span) {
            itemRows[d][ep.eventRow] = true;
          }
        }
        ep.classes.push(`offset${startOffset}`);
        ep.classes.push(`span${span}`);
        results.push(ep);
      }

      return results;
    },
    getWeekHolidayItems(weekStart) {
      // Return a list of items that CONTAIN the week starting on a day.
      // Sorted so the holiday items that start earlier are always shown first.
      const items = this.findAndSortHolidayItemsInWeek(weekStart);
      const results = [];
      const itemRows = [[], [], [], [], [], [], []];
      for (let i = 0; i < items.length; i++) {
        const ep = Object.assign({}, items[i], {
          classes: [...items[i].classes],
          eventRow: 0,
        });

        const continued = ep.startDate < weekStart;
        const startOffset = continued
          ? 0
          : this.dayDiff(weekStart, ep.startDate);
        const span = Math.min(
          7 - startOffset,
          this.dayDiff(this.addDays(weekStart, startOffset), ep.endDate) + 1
        );
        if (continued) ep.classes.push("continued");
        if (this.dayDiff(weekStart, ep.endDate) > 6)
          ep.classes.push("toBeContinued");
        if (this.isInPast(ep.endDate)) ep.classes.push("past");
        if (ep.originalEvent.url) ep.classes.push("hasUrl");
        for (let d = 0; d < 7; d++) {
          if (d === startOffset) {
            let s = 0;
            while (itemRows[d][s]) s++;
            ep.eventRow = s;
            itemRows[d][s] = true;
          } else if (d < startOffset + span) {
            itemRows[d][ep.eventRow] = true;
          }
        }
        ep.classes.push(`offset${startOffset}`);
        ep.classes.push(`span${span}`);
        results.push(ep);
      }

      return results;
    },
    getWeekBirthdayItems(weekStart) {
      // Return a list of items that CONTAIN the week starting on a day.
      // Sorted so the holiday items that start earlier are always shown first.
      const items = this.findAndSortBirthdayItemsInWeek(weekStart);
      const results = [];
      const itemRows = [[], [], [], [], [], [], []];
      for (let i = 0; i < items.length; i++) {
        const ep = Object.assign({}, items[i], {
          classes: [...items[i].classes],
          eventRow: 0,
        });

        const continued = ep.startDate < weekStart;
        const startOffset = continued
          ? 0
          : this.dayDiff(weekStart, ep.startDate);
        const span = Math.min(
          7 - startOffset,
          this.dayDiff(this.addDays(weekStart, startOffset), ep.endDate) + 1
        );

        if (continued) ep.classes.push("continued");
        if (this.dayDiff(weekStart, ep.endDate) > 6)
          ep.classes.push("toBeContinued");
        if (this.isInPast(ep.endDate)) ep.classes.push("past");
        if (ep.originalEvent.url) ep.classes.push("hasUrl");
        for (let d = 0; d < 7; d++) {
          if (d === startOffset) {
            let s = 0;
            while (itemRows[d][s]) s++;
            ep.eventRow = s;
            itemRows[d][s] = true;
          } else if (d < startOffset + span) {
            itemRows[d][ep.eventRow] = true;
          }
        }
        ep.classes.push(`offset${startOffset}`);
        ep.classes.push(`span${span}`);
        results.push(ep);
      }

      return results;
    },
    getWeekBirthdayAvatars(paramDate, weekStart) {
      return this.getWeekBirthdayItems(weekStart).filter(
        (item) => item.originalEvent.startDate == paramDate
      );
    },
    getWeekBirthdayGender(paramDate, weekStart) {
      let arrItems = this.getWeekBirthdayItems(weekStart).filter(
        (item) => item.originalEvent.startDate == paramDate
      );

      return arrItems.filter((item) => item.originalEvent.gender == "M")
        .length > 0
        ? true
        : false;
    },
    /*
  		Creates the HTML to prefix the item title showing the item's start and/or
  		end time. Midnight is not displayed.
  		*/
    getFormattedTimeRange(e) {
      const startTime = this.formattedTime(
        e.startDate,
        this.displayLocale,
        this.timeFormatOptions
      );
      let endTime = "";
      if (!this.isSameDateTime(e.startDate, e.endDate)) {
        endTime = this.formattedTime(
          e.endDate,
          this.displayLocale,
          this.timeFormatOptions
        );
      }
      return (
        (startTime !== ""
          ? `<span class="startTime">${startTime}</span>`
          : "") +
        (endTime !== "" ? `<span class="endTime">${endTime}</span>` : "")
      );
    },
    getItemTitle(e) {
      if (!this.showEventTimes) return e.title;
      return this.getFormattedTimeRange(e) + " " + e.title;
    },
    getItemTop(e) {
      // Compute the top position of the item based on its assigned row within the given week.
      const r = e.eventRow;
      const h = this.eventContentHeight;
      const b = this.eventBorderHeight;

      return `calc(${this.eventTop} + ${r}*${h} + ${r}*${b})`;
    },
    setItemColor(e) {
      // Set border and backgroundcolor to events
      if (e.originalEvent.color != undefined) {
        let color = e.originalEvent.color;
        return `background-color: ${color}20 !important; color: ${color} !important; border-left-color: ${color} !important; border-color: none !important;`;
      }

      return "";
    },
    getMultipleEvents(item, weekItems) {
      const dateStart = item.originalEvent.startDate,
        dateEnd = item.originalEvent.endDate;

      let arrStart = weekItems.filter(
        (item) => item.originalEvent.startDate == dateStart
      );
      let arrEnd = weekItems.filter(
        (item) => item.originalEvent.endDate == dateEnd
      );

      if (arrStart.length > 1) return true;
      if (arrEnd.length > 1) return true;

      let responseStart = false;
      let responseEnd = false;

      for (let i = 0; i < weekItems.length; i++) {
        let row = weekItems[i].originalEvent;
        const from = new Date(row.startDate),
          dateToCheck = new Date(dateStart);

        if (dateToCheck.getTime() >= from.getTime() && row.id != item.id) {
          responseStart = true;
          break;
        }
      }

      if (responseStart) {
        return true;
      }

      for (let i = 0; i < weekItems.length; i++) {
        let row = weekItems[i].originalEvent;
        const from = new Date(row.startDate),
          to = new Date(row.endDate),
          dateToCheck = new Date(dateEnd);

        if (
          dateToCheck.getTime() >= from.getTime() &&
          dateToCheck.getTime() <= to.getTime() &&
          row.id != item.id
        ) {
          responseEnd = true;
          break;
        }
      }

      if (responseEnd) {
        return true;
      }

      return false;
    },
    setItemHeight(e) {
      if (!e.hasMultiple) {
        return `height: 70px !important;`;
      }

      return "";
    },

    showCardInfo(event, weekStart) {
      const items = this.findAndSortItemsInWeek(weekStart);
      const results = [];

      for (let i = 0; i < items.length; i++) {
        const item = items[i];
        if (
          event.startDate >= item.startDate &&
          event.endDate <= item.endDate
        ) {
          results.push(item);
        }
      }
      this.$modal.show(
        CkModalEvents,
        {
          events: results,
        },
        {
          name: "calendarEventsModal",
          height: 569,
          width: 400,
        }
      );
    },
    showBirthdayCardInfo(itemBirthday, weekStart) {
      const results = this.getWeekBirthdayAvatars(
        itemBirthday.originalEvent.startDate,
        weekStart
      );

      this.$modal.show(
        CkModalBirthdays,
        {
          birthdays: results,
        },
        {
          name: "calendarBirthdaysModal",
          height: 569,
          width: 400,
        }
      );
    },
  },
};
</script>

<style>
.cv-wrapper {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;
  min-height: 100%;
  max-height: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
}
.cv-wrapper,
.cv-wrapper div {
  box-sizing: border-box;
  line-height: 1em;
  font-size: 1em;
}
.cv-header-days {
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: auto;
  flex-flow: row nowrap;
  border-width: 0 0 0 1px;
  background-color: #fff !important;
}
.cv-header-day {
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: 0;
  flex-flow: row nowrap;
  align-items: center;
  /* justify-content: center; */
  padding-left: 24px;
  padding-top: 40px;
  padding-bottom: 33px;

  background-color: #fff !important;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 16px !important;
  line-height: 22px;
  text-align: left !important;
  text-transform: capitalize;
  color: #96999a;
}
/* The calendar grid should take up the remaining vertical space */
.cv-weeks {
  display: flex;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: auto;
  flex-flow: column nowrap;
  border-width: 0 0 1px 1px;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  /* Allow grid to scroll if there are too may weeks to fit in the view */
  overflow-y: auto;
  -ms-overflow-style: none;
}
/* Use flex basis of 0 on week row so all weeks will be same height regardless of content */
.cv-week {
  display: flex;
  /* Shorthand flex: 1 1 0 not supported by IE11 */
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: 0;
  flex-flow: row nowrap;
  min-height: 11em;
  border-width: 0;
  /* Allow week events to scroll if they are too tall */
  position: relative;
  width: 100%;
  overflow-y: auto;
  -ms-overflow-style: none;
  /* Days of the week go left to right even if user's language is RTL (#138) */
  direction: ltr;
}
.cv-day {
  display: flex;
  /* Shorthand flex: 1 1 0 not supported by IE11 */
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: 0;
  position: relative; /* Fallback for IE11, which doesn't support sticky */
  position: sticky; /* When week's items are scrolled, keep the day content fixed */
  top: 0;
  border-width: 1px 1px 0 0;
  /* Restore user's direction setting (overridden for week) */
  direction: rtl;
  padding-right: 10px !important;
  padding-top: 10px !important;

  background-color: #fff;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  text-align: center;

  color: #96999a;
}
.cv-day-holiday {
  font-weight: 800 !important;
  font-size: 25px !important;
  line-height: 30px !important;

  color: #636997 !important;
}
.cv-day.today > div > div {
  /* background-color: #004baf !important; */
  background-color: #1E2245 !important;
  color: #fff !important;
  border-radius: 50%;
  text-align: center;
  vertical-align: middle;
  height: 30px;
  width: 30px;
  padding-top: 8px;
  margin-top: -8px;
}
.cv-day.cv-day-holiday.today > div > div {
  font-weight: 500 !important;
  font-style: normal !important;
  font-size: 18px !important;
  line-height: 22px !important;

  padding-top: 5px !important;
}
/* 
  A bug in Microsoft Edge 41 (EdgeHTML 16) has been reported (#109) where days "disappear" because they are
  wrapping under the next week (despite the "nowrap" on cv-week). This appears to be an issue specifically
  with our metrics and the sticky positioning. I was not able to reproduce this issue in Edge 38, 42, or 44.
  I'm reticent to turn off the sticky functionality for all Edge users because of one version (or perhaps an
  interaction of that version with a specific graphics adapter or other setting). So instead, I'm leaving this
  as an example for anyone forced to support Edge 41 who may see the same issue. If that's the case, just
  add this selector to your own CSS.
  @supports (-ms-ime-align: auto) {
    .cv-day {
      position: relative;
    }
  }
  */
_:-ms-lang(x),
.cv-day {
  position: relative;
}
*/ .cv-day-number {
  position: absolute;
  right: 0;
}
.cv-event {
  position: absolute;
  white-space: nowrap;
  overflow: hidden;
  background-color: #f7f7f7;
  border-width: 1px;
  /* Restore user's direction setting (overridden for week) */
  direction: initial;

  font-family: Montserrat !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 12px !important;
  line-height: 115.9% !important;
}
.cv-event .event-title {
  font-weight: 600 !important;
}
.cv-event-more {
  position: absolute;
  white-space: nowrap;
  overflow: hidden;
  background-color: transparent;
  /* Restore user's direction setting (overridden for week) */
  direction: initial;

  top: 9.2em;
}
.cv-avatar-more {
  position: absolute;
  white-space: nowrap;
  overflow: hidden;
  background-color: transparent;
  /* Restore user's direction setting (overridden for week) */
  direction: initial;

  top: 8.3em;
}
.cv-holiday {
  position: absolute;
  white-space: nowrap;
  overflow: hidden;
  background-color: transparent;
  /* Restore user's direction setting (overridden for week) */
  direction: initial;

  font-family: "Montserrat";
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;

  padding-top: 13px;
  padding-left: 10px;

  cursor: pointer;
}
.cv-birthday {
  position: absolute;
  white-space: nowrap;
  overflow: hidden;
  color: #fff;
  background-color: #004baf30;
  border-radius: 50px;

  margin-top: 10px;
  margin-left: 10px;
  padding-top: 11px;
  padding-left: 8px;
}
.cv-birthday.female {
  /* background-color: #8119ec35 !important; */
  background-color: #004baf30 !important;
}
.cv-birthday > i {
  padding-left: 2px;
  font-size: 28px !important;
}

.avatars {
  display: inline-flex;
  flex-direction: row;
  padding-left: 50px;
  float: right;
  margin-right: 7px;
}

.avatar {
  margin-left: -18px;
  position: relative;
  border: 1px solid #fff;
  border-radius: 50%;
  overflow: hidden;
  width: 30px;
  height: 30px;
  cursor: pointer;
}

.avatar img {
  width: 30px;
  height: 30px;
}

.info {
  border: 1px solid #96999a !important;
  background-color: #fff !important;
  font-family: "Montserrat" !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 12px !important;
  line-height: 15px !important;
  margin-left: 5px;
  padding: 6px;
  cursor: pointer;
}
/* Wrap to show entire item title on hover */
.cv-wrapper.wrap-event-title-on-hover .cv-event:hover {
  white-space: normal;
  z-index: 1;
}
/* Colors */
.cv-weeks,
.cv-week,
.cv-day {
  border-style: solid;
  border-color: #eff1fb;
}

.cv-event-more > div {
  font-family: "Montserrat";
  font-size: 12px;
  font-weight: 500;
  color: #BDBDBD !important;

  /* background-color: #004BAF !important; */
  border-radius: 25px;
  border: 1px solid #eff1fb;
  cursor: pointer;

  padding-top: 3px;
  padding-left: 8px;
  margin-left: calc(100% - 48%);
  height: 20px;
  width: 48%;
}

@media screen and (min-width: 1440px) {
  .cv-event-more > div {
    margin-left: calc(100% - 39%);
    width: 39%;
  }
}

/* .cv-event-more > div::after {
    content: " \2193";
    padding: 0 5px 0 0;
    color: white;
  } */

.cv-holiday {
  color: #636997;
}
.cv-today.cv-holiday {
  color: #96999a !important;
}
/* Item Times */
.cv-event .endTime::before {
  content: "-";
}
/* Internal Metrics */
.cv-day-number,
.cv-event-more,
.cv-event {
  padding: 0.2em;
}

.cv-day-holiday .cv-day-number {
  padding: 0em !important;
  margin-top: -3px;
}

.cv-day-holiday.today .cv-day-number {
  margin-top: 0px !important;
}
/* Allows emoji icons or labels (such as holidays) to be added more easily to specific dates by having the margin set already. */
.cv-day-number::before {
  margin-right: 0.5em;
}
.cv-event.offset0 {
  left: 0;
}
.cv-event.offset1 {
  left: calc((100% / 7));
}
.cv-event.offset2 {
  left: calc((200% / 7));
}
.cv-event.offset3 {
  left: calc((300% / 7));
}
.cv-event.offset4 {
  left: calc((400% / 7));
}
.cv-event.offset5 {
  left: calc((500% / 7));
}
.cv-event.offset6 {
  left: calc((600% / 7));
}

.cv-event-more.offset0,
.cv-avatar-more.offset0 {
  left: 0;
}
.cv-event-more.offset1,
.cv-avatar-more.offset1 {
  left: calc((100% / 7));
}
.cv-event-more.offset2,
.cv-avatar-more.offset2 {
  left: calc((200% / 7));
}
.cv-event-more.offset3,
.cv-avatar-more.offset3 {
  left: calc((300% / 7));
}
.cv-event-more.offset4,
.cv-avatar-more.offset4 {
  left: calc((400% / 7));
}
.cv-event-more.offset5,
.cv-avatar-more.offset5 {
  left: calc((500% / 7));
}
.cv-event-more.offset6,
.cv-avatar-more.offset6 {
  left: calc((600% / 7));
}
.cv-holiday.offset0,
.cv-birthday.offset0 {
  left: 0;
}
.cv-holiday.offset1,
.cv-birthday.offset1 {
  left: calc((100% / 7));
}
.cv-holiday.offset2,
.cv-birthday.offset2 {
  left: calc((200% / 7));
}
.cv-holiday.offset3,
.cv-birthday.offset3 {
  left: calc((300% / 7));
}
.cv-holiday.offset4,
.cv-birthday.offset4 {
  left: calc((400% / 7));
}
.cv-holiday.offset5,
.cv-birthday.offset5 {
  left: calc((500% / 7));
}
.cv-holiday.offset6,
.cv-birthday.offset6 {
  left: calc((600% / 7));
}
/* Metrics for items spanning dates */
.cv-holiday.span1 {
  width: calc((100% / 7) - 0.05em);
}
.cv-birthday.span1 {
  width: 48px;
  height: 48px;
}

.cv-event.span1 {
  width: calc((100% / 7) - 0.05em);
}
.cv-event.span2 {
  width: calc((200% / 7) - 0.05em);
}
.cv-event.span3 {
  width: calc((300% / 7) - 0.05em);
}
.cv-event.span4 {
  width: calc((400% / 7) - 0.05em);
}
.cv-event.span5 {
  width: calc((500% / 7) - 0.05em);
}
.cv-event.span6 {
  width: calc((600% / 7) - 0.05em);
}
.cv-event.span7 {
  width: calc((700% / 7) - 0.05em);
}

.cv-event-more.span1,
.cv-avatar-more.span1 {
  width: calc((100% / 7) - 0.05em);
}
/* Hide scrollbars for the grid and the week */
.cv-weeks::-webkit-scrollbar,
.cv-week::-webkit-scrollbar {
  width: 0; /* remove scrollbar space */
  background: transparent; /* optional: just make scrollbar invisible */
}
.btn_ver_mas{
  text-transform: none;

}

.cv-birthday2 {
  position: absolute;
  white-space: nowrap;
  overflow: hidden;
  color: #fff;
  background-color: transparent;
  border-radius: 0px;
  padding-top: 11px;
  padding-left: 8px;
  cursor: pointer;
}
.cv-birthday2.offset0 {
  left: 0;
}
.cv-birthday2.offset1 {
  left: calc((100% / 7));
}
.cv-birthday2.offset2 {
  left: calc((200% / 7));
}
.cv-birthday2.offset3 {
  left: calc((300% / 7));
}
.cv-birthday2.offset4 {
  left: calc((400% / 7));
}
.cv-birthday2.offset5 {
  left: calc((500% / 7));
}
.cv-birthday2.offset6 {
  left: calc((600% / 7));
}
.cv-birthday2.span1 {
  height: 100%;
  width: calc((100% / 7) - 0.05em);
}
</style>
