<template>
    <div>
        <div class="header">
            <span class="date-title">{{ displayDate }}</span>
            <span class="btn-close">
                <i @click="onClose" class="material-icons close-color close-size" style="float: right;">close</i>
            </span>
        </div>

        <div class="container">
            <template v-for="h in holidays">
                <div :key="h.id" class="item-event">
                    <div class="title title-border">
                        <div class="">
                            {{h.title}}
                        </div>
                        <div v-if="rol != 'empleado'" class="title subtitle" style="padding-top: 5px;">
                            {{ h.sucursal }}
                        </div>
                    </div>
                    <!-- <br /> -->
                    <div class="title subtitle">{{h.subtitle}}</div>
                    <v-divider v-if="holidays.length>1" class="division"></v-divider>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
export default {
    props: ['holidays', 'selectedDate'],
    data() {
        return {
            months: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
            datosLogin: null,
            rol: null,
        }
    },
    computed: {
        displayDate() {
            let arrDate = this.selectedDate.originalEvent.startDate.split("-");

            return `Día festivo ${arrDate[2]} ${this.months[parseInt(arrDate[1]) - 1]}`;
        }
    },
    methods: {
        onClose() {
            this.$modal.hide('calendarDayModal');
        },
    },
    mounted() {
       document.getElementsByClassName("vm--modal")[0].style.borderRadius = "25px";
    },
    created(){
        this.datosLogin = this.$session.get("usuario");
        this.rol = this.datosLogin.rol.name;
    }
}
</script>

<style scoped>
    .header {
        background-color: #636997;
        display: flex;
        height: 95px;
    }

    .header .date-title {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 115.9%;
        color: #FFFFFF;

        display: inline-block;
        padding-top: 50px;
        padding-left: 50px;
        padding-right: 50px;
    }

    .container {
        background-color: white;
        padding: 17px 50px 34px 50px;
        height: 420px;
        overflow-y: auto;
    }

    .btn-close {
        width: 100%;
        position: absolute;

        margin-top: 15px;
        padding-right: 28px;
        padding-bottom: 5px;
    }
    .close-color {
        color: #FFFFFF !important;
    }
    .close-size {
        font-size: 16px !important;
        cursor: pointer;
    }

    .item-event {
        padding: 10px;
        text-align: justify;
    }

    .title {
        font-family: 'Montserrat' !important;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 120%;

        color: #828282;
        padding-left: 10px;
    }
    .title.title-border {
        border-left-width: 2px;
        border-left-style: solid;
        border-left-color: #636997
    }
    .title.subtitle {
        font-weight: 500 !important;
        font-size: 12px !important;
        line-height: 15px !important;
        padding-left: 0px !important;
        padding-top:20px;
        text-align: left;
    }
    .division{
        margin-top: 20px;
    }
</style>