<template>
    <div>
        <v-card class="container scrollbar-template">
            <div xs="1" sm="1" md="1" lg="1" class="v-btn-alineacion background-transparent" style="float: right;">
                <v-btn
                    @click="onClose()"
                    small
                    slot="activator"
                    icon
                    text
                    class="v-btn-cerrar"
                >
                    <v-icon class="icono-cerrar"></v-icon>
                </v-btn>
            </div>
            <v-card-title class="padding_titulo_eventos">
                <div class="tituloEventos">
                    Cumpleaños de hoy
                </div>
            </v-card-title>
            <v-card-text class="vCardText_eventos" >
                <template v-for="b in birthdays">
                    <div :key="b.id" >
                        <div :id="b.id">
                            <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" style="padding:0px; padding-right:20px;">
                                <v-list color="transparent" class="py-0 my-0" style="background-color: transparent !important;">
                                    <v-list-item class="py-0 my-0 padding_izquierdo">
                                        <v-list-item-avatar height="48" width="48" class="py-0 my-0 mr-3">
                                            <v-img class="" height="48" width="48" :src="b.photo"></v-img>
                                        </v-list-item-avatar>
                                        <v-list-item-content class="ml-3 mr-3" style="background-color: transparent !important;">
                                            <v-list-item-title class="evento_empleado text-wrap" style="background-color: transparent !important;">{{b.originalEvent.title}}</v-list-item-title>
                                            <v-list-item-title class="evento_subtitulo text-wrap" style="background-color: transparent !important;">{{b.originalEvent.subtitle}}</v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-list>
                            </v-col>
                        </div>
                    </div>
                </template>
                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                </v-col>
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
import PerfectScrollbar from 'perfect-scrollbar';
export default {
    props: ['birthdays'],
    data() {
        return {
        }
    },
    methods: {
        onClose() {
            this.$modal.hide('calendarBirthdaysModal');
        },
        async scrollPerfect(){
            var myCustomScrollbar = document.querySelector('.scrollbar-template');
            var ps = new PerfectScrollbar(myCustomScrollbar);

            var scrollbarY = myCustomScrollbar.querySelector('.ps__rail-y');

            myCustomScrollbar.onscroll = function () {
                scrollbarY.style.cssText = `
                                                top: ${this.scrollTop}px!important; 
                                                height: 200px; 
                                                background-color:transparent; 
                                                right: ${-this.scrollLeft}px
                                            `;
            }
        },
    },
    mounted() {
        if(document.getElementsByClassName("vm--modal")[0]){
            document.getElementsByClassName("vm--modal")[0].style.borderRadius = "25px";
        }
        this.scrollPerfect();
    }
}
</script>

<style scoped>
    .container {
        background-color: white;
        overflow-y: scroll;
        height: 569px;
        padding: 0px;
    }

    .btn-close {
        width: 100%;
        position: absolute;

        margin-top: 15px;
        padding-right: 28px;
        padding-bottom: 5px;
    }
    .close-color {
        color: #96999A !important;
    }
    .close-size {
        font-size: 16px !important;
        cursor: pointer;
    }

    .item-event {
        width: 300px;
        min-height: 80px;
        border-radius: 80px;
        display: flex;
        align-items:center;
    }


    .photo-profile {
        height: 74px;
        width: 74px;
        border-radius: 50%;
    }

    .birthday-title {
        font-family: 'Montserrat' !important;
        font-size: 20px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.16;
        letter-spacing: normal;
        color: #96999A;

        padding-left: 33px;
    }
    .birthday-subtitle {
        font-size: 18px;
        font-weight: 500;
        line-height: 1.16;
    }

    .fade-enter-active, .fade-leave-active {
        transition: opacity .5s;
    }
    .fade-enter, .fade-leave-to {
        opacity: 0;
    }
    .v-btn-cerrar .v-btn:hover::before {
        background-color: transparent !important;
        pointer-events: none;
    }
    .v-btn-cerrar .v-btn:hover::after {
        background-color: transparent !important;
        pointer-events: none;
    }
    .v-btn-cerrar .v-btn:hover {
        background-color: transparent !important;
        pointer-events: none;
    }
    .v-btn-alineacion{
        /* text-align: end !important; */
        margin-right: 15px !important;
        margin-top: 10px !important;
        background: transparent !important;
    }
    .background-transparent{
        background: transparent !important;
    }
    .vCardText_eventos{
        padding-right: 0px !important;
        padding-left: 0px !important;
    }
    .tituloEventos{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 20px;
        color: #1E2245;
    }
    .evento_empleado{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 16px;
        color: #828282;
        padding-left: 10px;
    }
    .evento_subtitulo{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        line-height: 14px;
        font-size: 12px;
        color: #828282;
        padding-left: 10px;
    }
    .padding_titulo_eventos{
        padding-left: 50px !important;
        padding-right: 50px !important;
        padding-bottom: 35px !important;
        padding-top: 50px !important;
    }
</style>