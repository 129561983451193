import { URL_IMAGEN_BASE_URL } from "../plugins/variables";

const getURL = () => {
  var host = window.location.hostname;
  let baseUrl = URL_IMAGEN_BASE_URL;

  if (host === "payroll.cliker.com.mx" || host === "www.payroll.cliker.com.mx") {
    baseUrl = "https://apigdth.cliker.com.mx/";
  } else if (
    host === "devmid19.pbmg.com.mx" ||
    host === "www.devmid19.pbmg.com.mx" ||
    host === "payrolldev.pbmg.com.mx/" ||
    host === "www.payrolldev.pbmg.com.mx/"
  ) {
    baseUrl = "https://apidevmid19.pbmg.com.mx/";
  }
  return baseUrl;
};

const date_format = (date, tipo = "db") => {
  if (date == null) {
    date = new Date();
  }

  let d = date.getDate();
  let m = date.getMonth() + 1;
  d = d.toString();
  m = m.toString();
  let dia = d.length == 1 ? "0" + d : d;
  let mes = m.length == 1 ? "0" + m : m;
  let final = "";

  if (tipo == "db") {
    final = date.getFullYear() + "-" + mes + "-" + dia;
  } else {
    final = dia + "/" + mes + "/" + date.getFullYear();
  }
  return final;
};

export default {
  getURL,
  date_format,
};
